import { BiChevronRight } from "react-icons/bi";
import { useGlobalStoreContext } from "../../../context/GlobalStoreContext";
import { formatCurrency } from "../../../utils/santaAppUtils";
import { Link } from "react-router-dom";
import moment from "moment-timezone";

// Set default timezone to Asia/Kolkata
moment.tz.setDefault("Asia/Kolkata");

export default function DailyView({
  week = moment().isoWeek(),
  year = moment().year(),
}) {
  const {
    storeStates: {
      santaEarningsData: { weeklyEarnings },
    },
  } = useGlobalStoreContext();

  const weekDateObj = moment().isoWeek(week).year(year);
  const isCurrentWeek = moment().isSame(weekDateObj, "isoWeek");

  const currentWeekEarning = weeklyEarnings?.find(
    (curr) =>
      parseInt(curr.week) === parseInt(week) &&
      parseInt(curr.year) === parseInt(year)
  );

  const currentWeekDaysCountArray = new Array(
    moment().isoWeekday() === 7 || moment().isoWeekday() === 0 || !isCurrentWeek
      ? 7
      : moment().isoWeekday()
  ).fill(0);

  return (
    <div className="rounded-[.5rem]  w-full">
      {currentWeekDaysCountArray?.map((_, idx) => {
        const currDayNumber = isCurrentWeek
          ? moment().isoWeekday() === 0 || moment().isoWeekday() === 7
            ? 7
            : moment().isoWeekday()
          : 7;

        const currDateObj = weekDateObj
          .startOf("isoWeek")
          .add(currDayNumber - (idx + 1), "days");

        const currDate = currDateObj.format("YYYY-MM-DD");
        const orders = currentWeekEarning?.dayWiseOrders?.find(
          (currObj) => currObj?._id === currDate
        );

        const walletTransactions =
          currentWeekEarning?.dayWiseWalletTransactions?.find(
            (currObj) => currObj?._id === currDate
          );

        const otherEarnings =
          (walletTransactions?.otherCreditTotal || 0) -
          (walletTransactions?.otherDebitTotal || 0);

        const earnings =
          (walletTransactions?.ordersEarnings || 0) + otherEarnings;

        const orderCount = orders?.orders?.length || 0;

        return (
          <Link
            to={`/earnings/week/${year}/${week}/${currDate}`} // ex: /earnings/week/2022/32/2022-08-08
            key={idx}
            className={` ${
              idx !== 0 && "border-t"
            } p-4 flex justify-between items-center`}
          >
            <div>
              <p className=" text-[.875rem]">{getDateFormate(currDateObj)}</p>
              {orderCount > 0 && (
                <p className="text-[.75rem] text-[gray]">
                  {orderCount} {orderCount > 1 ? "Orders" : "Order"}
                </p>
              )}
            </div>

            <div className="flex items-center justify-start gap-2">
              <p className="font-semibold">{formatCurrency(earnings)}</p>
              <BiChevronRight className="text-[1.125rem] text-[#2136D4]  " />
            </div>
          </Link>
        );
      })}
    </div>
  );
}

const getDateFormate = (dateObj) => {
  // if (moment().isSame(dateObj, "day")) {
  //   return "Today";
  // }
  // if (moment().subtract(1, "days").isSame(dateObj, "day")) {
  //   return "Yesterday";
  // }
  return dateObj.format("ddd, DD MMM");
};
