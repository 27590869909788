import React from "react";
import { AnimatePresence, motion } from "framer-motion";
import { FaAngleLeft } from "react-icons/fa";
import DecorPreview from "./orderSummarySubC/DecorPreview";
import SantaInstructions from "./orderSummarySubC/SantaInstructions";
import MaterialsList from "../../../../../container/material/list/MaterialsList";
import { TbCategoryPlus } from "react-icons/tb";
import ExpertHelp from "../../../../../../assets/headers/ExpertHelp";

export default function OrderSummaryIdx({
  order,
  states,
  setStates,
  isAcceptedOrder = true,
}) {
  return (
    <AnimatePresence>
      {states.orderSummaryPage && (
        <Modal
          order={order}
          states={states}
          isAcceptedOrder={isAcceptedOrder}
          setStates={setStates}
        />
      )}
    </AnimatePresence>
  );
}

const Modal = ({ states, setStates, order, isAcceptedOrder }) => {
  const handleClose = () =>
    setStates((p) => ({ ...p, orderSummaryPage: false }));

  return (
    <motion.div
      initial={{ x: "100%" }}
      animate={{ x: 0 }}
      exit={{ x: "100%" }}
      transition={{ duration: 0.3 }}
      className="fixed bottom-0  right-0 left-0 top-0 bg-blue-50 flex flex-col   text-[.875rem] items-center z-[98]"
    >
      <div className=" flex  px-4 border-gray-200 border-b bg-white pt-1 right-0 z-[2]  left-0 top-0 absolute h-[3rem] items-center justify-between  ">
        <div className="flex items-center gap-1">
          <FaAngleLeft
            onClick={handleClose}
            className="text-[1.25rem] text-[black]"
          />
          <div className="ml-2 text-[1rem] font-[500]">Back</div>
        </div>
        <ExpertHelp />
      </div>
      <div className="flex flex-col w-full overflow-scroll scroll-bar-remove  pb-[2rem] pt-[4rem] ">
        <DecorPreview order={order} />
        <SantaInstructions order={order} />
        <div className="flex flex-col w-full px-4 ">
          <div className="w-full text-[#171717] flex flex-col gap-2 border-t border-dotted mt-4 pt-2">
            <div className=" font-[600] text-[1rem] flex justify-between item-center">
              <p className="flex items-center gap-1">
                <TbCategoryPlus className=" relative bottom-[1px]" />
                Materials
              </p>
            </div>
            <MaterialsList rawMaterials={order?.productId.rawMaterials} />
          </div>
        </div>
      </div>
    </motion.div>
  );
};
