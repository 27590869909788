import React, { useContext } from "react";
import SantaTeam from "../subC/santaTeam/SantaTeam";
import Header2 from "../../../../assets/headers/Header2";
import { useNavigate } from "react-router-dom";
import { LuCalendarX2 } from "react-icons/lu";
import { FaLocationArrow, FaRegCopy } from "react-icons/fa";
import AuthContext from "../../../../context/AuthContext";
import moment from "moment-timezone";

// Set default timezone to Asia/Kolkata
moment.tz.setDefault("Asia/Kolkata");

export default function PastOrder({ order }) {
  const address = order.addressId;
  const delivery = order.date.delivery;
  const nav = useNavigate();
  const {
    initialState: { santa },
  } = useContext(AuthContext);

  return (
    <>
      <div className=" fixed top-0 flex flex-col pt-[5rem] overflow-scroll scroll-bar-remove pb-[6rem] bg-blue-50 items-center right-0 left-0 bottom-0 ">
        <Header2 navFun={() => nav("/orders")} title={"Back to orders"} />

        <div className="flex flex-col w-full items-center justify-center h-[8rem] mb-4">
          <LuCalendarX2 className="text-[8rem]  text-red-800 " />
          <div className=" mt-2 ">You missed the order</div>
          <div
            onClick={() => {
              window.copyContent(order._id, "Order ID copied");
            }}
            className=" flex cursor-pointer gap-1 text-[gray] items-center "
          >
            #{order._id} <FaRegCopy />
          </div>
        </div>

        <div className="px-4 mt-4 w-full">
          <div className="w-full border gap-1 flex items-center text-[.875rem] border-red-200 text-red-700 bg-red-50 rounded px-2 py-1  ">
            For more details
            <span
              onClick={() => {
                window.openUrl(
                  `https://wa.me/7506669655?text=Hi, I'm ${santa.personalDetails?.name} an ebo santa. I'm have query regarding order #${order._id}`
                );
              }}
              className="underline "
            >
              reach us
            </span>
          </div>
        </div>
        <div className="px-4 mt-4 w-full">
          <div className=" w-full flex flex-col  text-[.875rem] font-[400] p-4 bg-[white] rounded-lg border border-gray-200  ">
            <div className="text-[gray]  text-[.8125rem]  ">Service area</div>
            <div className="w-full flex items-start gap-2">
              <div className=" p-2 mt-1 bg-blue-50 text-blue-600 rounded-full  text-[1.25rem] ">
                <FaLocationArrow />
              </div>

              <div className=" flex flex-col w-full py-[2px]">
                <div className="text-black text-[1rem]  ">
                  {address.locationv2.addressLine1}
                </div>
                <div className="text-[gray] text-[.875rem] font-[400] leading-[18px]  ">
                  {address.locationv2.city} - {address.locationv2.pincode}
                </div>
              </div>
            </div>

            <div className="text-[gray] mt-2  text-[.8125rem]  ">
              Service date was
            </div>
            <div className="  ">
              {moment(delivery?.celebrationDate).format("ddd, D MMM")}
            </div>
            <div className="text-[gray] mt-2  text-[.8125rem] ">
              Work time needed
            </div>
            <div className="   ">{delivery.workHour} Hrs</div>
            <div className="text-[gray] mt-2  text-[.8125rem] ">
              Arrival time
            </div>
            <div className="  ">
              {moment(delivery.arrivingTime, "HH").format("hh:mm A")} -{" "}
              {moment(delivery.arrivingTime, "HH")
                .add(1, "hours")
                .format("hh:mm A")}
            </div>
          </div>
        </div>

        <div className="px-4 mt-6 w-full">
          <p className=" text-[1.125rem] w-full text-center font-[600] ">
            <span className="text-[gray]">Santa </span>
            <span className="   text-[black]">Team</span>
          </p>
          <SantaTeam santaGroups={order?.santaGroups} />
        </div>
      </div>
    </>
  );
}
