import { useContext, useRef, useState } from "react";
import usePullToRefresh, { RefreshLoader } from "../../hooks/usePullToRefresh";
import AuthContext from "../../context/AuthContext";
import AccountBlockedPage from "./subComps/AccountBlockedPage";
import ReviewOnboardingPage from "./subComps/ReviewOnboardingPage";
import Header3 from "../../assets/headers/Header3";
import OnboardingRejectedPage from "./subComps/OnboardingRejectedPage";
import useFetchInitialData from "../../hooks/useFetchInitialData";
import AccountSuspendedPage from "./subComps/AccountSuspendedPage";
import AccountDeactivatedPage from "./subComps/AccountDeactivatedPage";

export default function ActionPageIdx({ pageType }) {
  const {
    logout,
    initialState: { santa },
  } = useContext(AuthContext);
  const { callMethods } = useFetchInitialData();
  const containerRef = useRef(null);
  const [refereshStates, setRefereshStates] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  usePullToRefresh(
    containerRef,
    async () => {
      setIsLoading(true);
      await callMethods({ refetchData: true });
      setIsLoading(false);
    },
    (params) => {
      setRefereshStates(params);
    },
    {
      isGradient: false,
      loaderColor: "#2136d4",
    }
  );

  if (isLoading)
    return (
      <div
        ref={containerRef}
        className="z-[199] fixed top-0 pt-[3rem] left-0 bottom-0 right-0 flex items-center justify-center bg-blue-50"
      >
        <Header3 />

        <div className="loader w-[2rem]"></div>
      </div>
    );

  const pageComponents = {
    accountBlocked: <AccountBlockedPage santa={santa} />,
    onboardingInReview: <ReviewOnboardingPage santa={santa} />,
    onboardingRejected: <OnboardingRejectedPage santa={santa} />,
    suspended: <AccountSuspendedPage santa={santa} />,
    deactivated: <AccountDeactivatedPage santa={santa} />,
  };

  return (
    <>
      <div
        ref={containerRef}
        className="z-[199] fixed top-0 pt-[3rem] left-0 bottom-0 right-0 overflow-x-hidden overflow-y-scroll scroll-bar-remove bg-blue-50"
      >
        <Header3 />
        <RefreshLoader refereshStates={refereshStates} />

        {pageComponents[pageType]}

        {/* {pageType === "accountBlocked" ? (
          <AccountBlockedPage santa={santa} />
        ) : pageType === "onboardingInReview" ? (
          <ReviewOnboardingPage />
        ) : (
          pageType === "onboardingRejected" && (
            <OnboardingRejectedPage santa={santa} />
          )
        )} */}

        <div className="fixed bottom-0 left-0 right-0 px-2 py-2 bg-white w-full flex flex-col items-center gap-2">
          <button
            onClick={() => {
              window.openUrl(
                `https://wa.me/7506669655?text="Hi, I'm looking for support regarding my account review."`
              );
            }}
            className="blue-gradient w-full rounded-lg  text-white font-bold px-4 py-3 srounded-[.5rem]"
          >
            Contact Support
          </button>
          <button
            onClick={() => {
              window.warningModal({
                msg: "Are you sure you want to logout from this account?",
                lb_text: "Logout",
                lb_fn: logout,
                rb_text: "No",
              });
            }}
            className=" text-red-500 font-bold"
          >
            Logout
          </button>
        </div>
      </div>
    </>
  );
}
