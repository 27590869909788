import React, { useMemo } from "react";

export default function MaterialsList({ rawMaterials }) {
  const groupedMaterials = useMemo(() => {
    // First, group the materials by their category.
    // If a material doesn't have a category, default to "other"
    const groups = rawMaterials.reduce((acc, material) => {
      const item = material.item ? material.item : material.customItem;
      let category = item?.category ? item.category : "other";

      if (category?.startsWith("alphabet")) {
        category = "foil - alphabet";
      }
      if (category?.startsWith("number")) {
        category = "foil - number";
      }

      if (!acc[category]) {
        acc[category] = [];
      }
      acc[category].push(material);
      return acc;
    }, {});

    // Sort each category's materials by their item name.
    Object.keys(groups).forEach((cat) => {
      groups[cat].sort((a, b) => {
        const itemA = (a.item ? a.item : a.customItem)?.itemName || "";
        const itemB = (b.item ? b.item : b.customItem)?.itemName || "";
        return itemA.localeCompare(itemB);
      });
    });

    // Convert the groups object into an array of objects and sort the groups
    // by category name, placing the "other" category at the end.
    const sortedGroups = Object.keys(groups)
      .sort((a, b) => {
        if (a === "other") return 1; // a comes after b if a is "other"
        if (b === "other") return -1; // b comes after a if b is "other"
        return a.localeCompare(b);
      })
      .map((category) => ({
        category,
        materials: groups[category],
      }));

    return sortedGroups;
  }, [rawMaterials]);

  return (
    <div className="w-full flex-col gap-6 pt-2 relative">
      {groupedMaterials.map(({ category, materials }, idx) => {
        return <EachGroup key={idx} {...{ category, materials }} />;
      })}
    </div>
  );
}

const EachGroup = ({ category, materials }) => {
  const groupLength = materials.length;

  return (
    <div className="relative flex-shrink-0">
      <div
        className=" absolute -top-[14px] left-0 capitalize px-2 pt-0.5 pb-2 blue-gradient
       rounded font-medium text-2xs z-[1] "
      >
        {category}
      </div>
      <div className="border border-gray-200 z-[2] rounded-lg  relative px-3 py-1 bg-white  ">
        {category?.startsWith("foil -") ? (
          <AlphabetGroup alphabetGroup={materials} />
        ) : (
          materials.map?.((curr, idx) => {
            const item = curr.item ? curr.item : curr.customItem;
            const itemImage =
              item?.images?.[0] || item?.image || curr.customItem?.image;

            const isRental = curr?.tags?.includes("rental");

            return (
              <div
                key={idx}
                style={{
                  borderBottom:
                    groupLength - 1 === idx ? "none" : "1px solid #d9d9d9",
                }}
                className={`flex justify-between items-center border-b py-2 px-3 `}
              >
                <div className="flex gap-4 w-[80%] items-center">
                  <div className="w-[90%] flex items-center gap-3">
                    <img
                      src={itemImage}
                      alt="material"
                      onClick={() => {
                        window.showMsg(itemImage, "img");
                      }}
                      className=" rounded-full overflow-hidden w-[2rem] h-[2rem] aspect-square bg-blue-50 "
                    />
                    <div className="flex-col max-w-[70%]">
                      <p className="text-black first-letter:capitalize text-ellipsis overflow-hidden whitespace-nowrap font-medium">
                        {item?.itemName}
                      </p>
                      {isRental && (
                        <span
                          className=" capitalize text-[#2136d4] w-[3rem] flex-center flex-grow-0
                   py-[1px] bg-blue-50 font-medium rounded text-2xs "
                        >
                          Rental
                        </span>
                      )}
                    </div>
                  </div>
                </div>
                <div className="flex gap-2 items-center ">
                  <div className="rounded-full font-[500] bg-blue-50 text-[#2136d4] text-xs px-1.5 py-1 flex-center ">
                    x{curr.quantity + curr.extraQuantity}{" "}
                    {curr.packetType === "meter"
                      ? "M"
                      : curr.packetType === "liter"
                      ? "L"
                      : ""}
                  </div>
                </div>
              </div>
            );
          })
        )}
      </div>
    </div>
  );
};

const AlphabetGroup = ({ alphabetGroup }) => {
  const { groupedByTag, characterCounts } = useMemo(() => {
    // Group materials by foil tag. The regex pattern is: [content]-[foil type]-foil.
    // 'groupedByTag' will have keys like "SHIVAM" or "23".
    const groups = {};
    // For character count, group by the material's character _id (i.e. material.item._id)
    const charGroups = {};

    alphabetGroup.forEach((material) => {
      // Grouping by tag using the foil tag in material.tags
      const tag = material.tags?.find((t) => /^([^-]+)-([^-]+)-foil$/.test(t));
      if (tag) {
        const match = tag.match(/^([^-]+)-([^-]+)-foil$/);
        if (match) {
          const groupKey = match[1];
          if (!groups[groupKey]) {
            groups[groupKey] = [];
          }
          groups[groupKey].push(material);
        }
      }
      // Grouping for character count using material.item._id
      const item = material.item ? material.item : material.customItem;
      if (item && item._id) {
        if (!charGroups[item._id]) {
          charGroups[item._id] = [];
        }
        charGroups[item._id].push(material);
      }
    });

    // For each tag-based group, sort materials based on the sequence defined by the tag's content.
    Object.keys(groups).forEach((groupKey) => {
      const sequence = groupKey.split(""); // e.g., ['S','H','I','V','A','M'] or ['2','3']
      groups[groupKey].sort((a, b) => {
        const nameA = ((a.item ? a.item : a.customItem)?.itemName || "").trim();
        const nameB = ((b.item ? b.item : b.customItem)?.itemName || "").trim();
        const letterA = nameA[0]?.toUpperCase() || "";
        const letterB = nameB[0]?.toUpperCase() || "";
        const indexA =
          sequence.indexOf(letterA) === -1
            ? Infinity
            : sequence.indexOf(letterA);
        const indexB =
          sequence.indexOf(letterB) === -1
            ? Infinity
            : sequence.indexOf(letterB);
        // Fallback to lexicographical order if same letter or not found in sequence.
        if (indexA === indexB) {
          return nameA.localeCompare(nameB);
        }
        return indexA - indexB;
      });
    });

    // For character count: group by material.item._id and then sort the keys
    // "Sort according to word" means sorting by the corresponding item's name.
    const sortedCharacterCounts = Object.entries(charGroups)
      .sort(([, matsA], [, matsB]) => {
        const nameA = (
          (matsA[0].item ? matsA[0].item : matsA[0].customItem)?.itemName || ""
        ).toLowerCase();
        const nameB = (
          (matsB[0].item ? matsB[0].item : matsB[0].customItem)?.itemName || ""
        ).toLowerCase();
        return nameA.localeCompare(nameB);
      })
      .reduce((acc, [id, mats]) => {
        acc[id] = { count: mats.length, material: mats[0] };
        return acc;
      }, {});

    return { groupedByTag: groups, characterCounts: sortedCharacterCounts };
  }, [alphabetGroup]);

  return (
    <div className="flex-col w-full py-2 ">
      <div
        className={`flex items-center gap-2 w-full flex-wrap 
    mb-2 pb-2 `}
      >
        {Object.values(characterCounts).map(({ count, material }, idx) => {
          const item = material.item ? material.item : material.customItem;
          const itemImage =
            item?.images?.[0] || item?.image || material.customItem?.image;

          return (
            <div className="relative flex-shrink-0" key={idx}>
              <img
                className=" size-[2.75rem] rounded flex-shrink-0 border border-gray-200 object-cover p-1 bg-white-50 "
                alt={item?.itemName}
                onClick={() => {
                  window.showMsg(itemImage, "img");
                }}
                src={itemImage}
              />
              <div className="rounded-full absolute -bottom-1 -right-1 font-[500] text-[9px] bg-blue-100 text-[#2136d4] size-[18px] flex-center ">
                x{count}
              </div>
            </div>
          );
        })}
      </div>
      {Object.values(groupedByTag)?.map((materials, idx) => {
        const content = Object.keys(groupedByTag)[idx];
        let foil = materials[0]?.tags
          ?.find((t) => /^([^-]+)-([^-]+)-foil$/.test(t))
          ?.split("-")?.[1];

        if (foil === "gold") {
          foil = "golden";
        }

        return (
          <div
            key={idx}
            className={`flex items-center text-sm mt-2 gap-1 px-1 w-full overflow-scroll flex-nowrap  `}
          >
            <span className=" text-gray-600"> Added -</span>
            <span className="font-medium border-b border-dotted border-gray-400 uppercase text-[#2136d4]">
              {content}
            </span>
            <span className=" capitalize px-1 ml-2 py-0.5 bg-gray-100 font-medium rounded text-2xs ">
              {foil} foil
            </span>
          </div>
        );
      })}
    </div>
  );
};

// sequence of the tags
// balloon , foil , rentals ( stands )
