import { useContext } from "react";
import { IoHelp } from "react-icons/io5";
import AuthContext from "../../context/AuthContext";
import santaAppUtils from "../../utils/santaAppUtils";

export default function Header3({ handleBack }) {
  const {
    initialState: { santa },
  } = useContext(AuthContext);

  return (
    <div
      className="flex justify-between z-[15] w-full fixed items-center top-0 right-0 left-0 border-b  bg-white mb-4 p-2  
          "
    >
      <div
        className="  text-[2rem] leading-[2rem] relative top-[2px]  text-[#2136d4] font-[900]  "
        style={{ fontFamily: "Josefin Sans, sans-serif" }}
        onClick={() => {
          handleBack && handleBack();
        }}
      >
        ebo
      </div>

      <div className="flex  gap-4 mt-1 pr-1">
        <div
          onClick={() => {
            window.openUrl(
              `https://wa.me/${santaAppUtils().whatsappNumber}?text="Hi, I'm ${
                santa.personalDetails?.name
              } an ebo santa. I'm facing an issues"`
            );
          }}
          className=" h-fit w-fit text-white bg-black bg-opacity-30 rounded-full p-[6px]"
        >
          <IoHelp className="text-[1.25rem]" />
        </div>
      </div>
    </div>
  );
}
