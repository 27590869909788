import React, { useContext, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { RxCross2 } from "react-icons/rx";
import { BsCheck, BsQrCode, BsQrCodeScan } from "react-icons/bs";
import {
  sendPaymentLinkSmsToCustomer,
  updateOrderForSantaApi,
} from "../../../../../../api/orderApi";
import { projectionString } from "../../../subC/helperFunctions";
import AuthContext from "../../../../../../context/AuthContext";

import { FaAngleRight } from "react-icons/fa";
import { rzpAPI } from "../../../../../../api/pay/pay.api";

import moment from "moment-timezone";

// Set default timezone to Asia/Kolkata
moment.tz.setDefault("Asia/Kolkata");
export default function CashCollectModal({
  setParentStates,
  order,
  states,
  setStates,
}) {
  return (
    <AnimatePresence>
      {states.cashCollectModal && (
        <Modal
          order={order}
          setParentStates={setParentStates}
          states={states}
          setStates={setStates}
        />
      )}
    </AnimatePresence>
  );
}

const Modal = ({ setParentStates, states, setStates, order }) => {
  const [modalStates, setModalStates] = useState({
    isLoading: false,
    generatingLink: false,
  });
  const {
    initialState: { santa },
  } = useContext(AuthContext);
  const handleClose = () =>
    setStates((p) => ({ ...p, cashCollectModal: false }));

  const handleCashCollection = async () => {
    try {
      const leaderSanta = (
        order.santaGroups?.santas?.find((_) => _.tags?.includes("leader")) ||
        order.santaGroups?.santas[0]
      )?.santa;

      const isLeaderSantaAccount = leaderSanta._id === santa._id;

      setModalStates((p) => ({ ...p, isLoading: true }));

      const res = await updateOrderForSantaApi({
        queryObject: { _id: order._id },
        updateObject: {
          "priceDetail.pendingAmount": 0,
          "priceDetail.paymentStatus": "paid",
          $push: {
            "priceDetail.paymentMode": {
              mode: "cash",
              date: moment().toISOString(),
              amount: order.priceDetail.pendingAmount,
            },
            "priceDetail.cashCollector": [
              {
                collector: leaderSanta?.personalDetails?.name,
                amount: order.priceDetail?.pendingAmount,
                collectorId: leaderSanta._id,
                collectorType: "santa",
                remark: isLeaderSantaAccount
                  ? `Cash collected by santa`
                  : `Cash collected by santa team member: ${santa.personalDetails.name}  (${santa._id})`,
                tags: isLeaderSantaAccount ? `` : ["teamMemberCIHCollect"],
                date: moment().toISOString(),
              },
            ],
          },
        },
        tags: ["moneyCollectedBySanta"],
        projectionString: projectionString,
      });

      if (res.isSuccess) {
        setParentStates((p) => ({
          ...p,
          order: res.order,
        }));
        setModalStates((p) => ({ ...p, isLoading: false }));
        handleClose();
      } else {
        window.toast("Something went wrong", "error");
        setModalStates((p) => ({ ...p, isLoading: false }));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const sendPaymentLink = async () => {
    try {
      setModalStates((p) => ({ ...p, generatingLink: true }));
      const res = await rzpAPI({
        options: {
          amount: order.priceDetail.pendingAmount,
          description: "Payment for order #" + order._id,
          callback_url: "https://www.ebonow.com",
          operation: "createPaymentLink",
          customerName: order.userId?.name,
          sendSmsByRZP: false,
          customerContact: order.userId?.phoneNumber,
          customerEmail: order.userId?.email,
          notes: {
            paymentFor: "order",
            paymentFor_id: order._id,
            paymentInitiaterDomain: "ebo-admin",
          },
        },
      });
      if (res.isSuccess && res.data.short_url) {
        await sendPaymentLinkSmsToCustomer({
          name: order.userId?.name,
          orderId: "#" + order._id,
          amount: order.priceDetail.pendingAmount,
          phoneNumber: order.userId?.phoneNumber,
          link: res.data.short_url,
        });

        setStates((p) => ({
          ...p,
          paymentLinkSentToCustomer: true,
          paymentLinkSentToCustomerTime: moment().toISOString(),
        }));

        window.toast("Payment link sent to customer", "success");
      } else {
        window.toast("Error while generating payment link", "error");
      }
      setModalStates((p) => ({ ...p, generatingLink: false }));
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className=" fixed top-0 left-0 bottom-0 right-0 z-[2] bg-[#0000006d] ">
      <motion.div
        initial={{ y: "42rem" }}
        animate={{ y: 0 }}
        exit={{ y: "42rem" }}
        transition={{ duration: 0.3 }}
        className="fixed bottom-0 left-0 flex flex-col h-[38rem] rounded-t-xl text-[.875rem] items-center right-0 bg-[white] z-[1]"
      >
        <div className=" absolute top-4 left-4 ">
          <RxCross2
            onClick={handleClose}
            className="text-[1.5rem] text-[black]"
          />
        </div>
        <div
          onClick={() => {
            window.toast("Something went wrong", "error");
          }}
          className="mt-[3rem] text-[1.125rem] font-[500]"
        >
          <span className="text-[#2136d4] ">Cash</span> to collect
        </div>
        <div className="text-[2rem] font-[800] mt-1 leading-[30px] ">
          ₹{order.priceDetail.pendingAmount}
        </div>
        <div className="mt-[1rem] text-[gray] text-[1rem]">
          Ask customer for the payment
        </div>
        <div className="flex gap-4 w-full items-center mt-4 justify-center">
          <hr className="border-t-[2px] border-gray-400 w-[30%]" />
          <div className=" text-[1rem] text-[gray]">OR</div>
          <hr className="border-t-[2px] border-gray-400 w-[30%]" />
        </div>
        <BsQrCode className="text-[6rem] mt-[3rem] blur-lg  " />

        <div
          onClick={() =>
            !modalStates.isLoading &&
            setStates((p) => ({
              ...p,
              showQrModal: true,
              cashCollectModal: false,
            }))
          }
          className={` ${
            modalStates.isLoading && "opacity-60"
          } relative bottom-[4.5rem]  py-1 px-[2rem] gap-1 font-[500] blue-gradient flex items-center rounded-lg justify-center text-[1rem] `}
        >
          <BsQrCodeScan className="text-[1rem] relative bottom-[1px] " /> Show
          QR
        </div>
        <div className="flex gap-4 w-full items-center mt-4 justify-center">
          <hr className="border-t-[2px] border-gray-400 w-[30%]" />
          <div className=" text-[1rem] text-[gray]">OR</div>
          <hr className="border-t-[2px] border-gray-400 w-[30%]" />
        </div>
        {states.paymentLinkSentToCustomer ? (
          <div className="mt-[3rem] flex items-center gap-2 text-[1rem] border rounded-lg  py-1 px-4 border-blue-200 bg-blue-50 font-[500]">
            Link sent to customer{" "}
            <BsCheck className="text-[green] text-[1.5rem] " />
          </div>
        ) : modalStates.generatingLink ? (
          <div className="mt-[3rem] flex items-center gap-2 text-[1rem] border rounded-lg  py-1 px-4 border-blue-200 bg-blue-50 font-[500]">
            Sending... <div className="loader w-[1rem] " />
          </div>
        ) : (
          <div
            onClick={() => {
              sendPaymentLink();
            }}
            className="mt-[3rem] flex items-center gap-1 text-[1rem] border rounded-lg  py-1 px-4 border-blue-200 bg-blue-50 font-[500]"
          >
            Send <span className="text-[#2136d4] ">payment link</span> to
            customer <FaAngleRight />
          </div>
        )}

        <div
          onClick={() => {
            !modalStates.isLoading &&
              window.warningModal({
                msg:
                  "Collected ₹" +
                  order.priceDetail.pendingAmount +
                  " successfully?",
                rb_text: "Yes",
                rb_fn: handleCashCollection,
                lb_text: "No",
                lb_fn: () => {
                  window.toast("Collect the money first.");
                },
              });
          }}
          className=" w-[95%] h-[3.5rem] py-3 px-[5rem] gap-1 mt-[3rem] mirror-Animation before:w-[20px] font-[500] blue-gradient flex items-center rounded-lg justify-center text-[1.125rem] "
        >
          {modalStates.isLoading ? (
            <div
              style={{ borderTopColor: "white" }}
              className="loader w-[1.25rem]"
            ></div>
          ) : (
            "Collect ₹" + order.priceDetail.pendingAmount
          )}
        </div>
      </motion.div>
    </div>
  );
};
