import { FaRegCircle } from "react-icons/fa";
import { MdOutlineMeetingRoom } from "react-icons/md";
import { PiNotepad } from "react-icons/pi";

export const CUSTOMER_HELP_NUMBER = 7506669655;
export const SANTA_HELP_NUMBER = "+917506669655";

export const commonServiceTags = [
  { tagName: "ring", icon: <FaRegCircle /> },
  { tagName: "room", icon: <MdOutlineMeetingRoom /> },
  { tagName: "stand", icon: <PiNotepad /> },
  { tagName: "l shape", icon: <></> },
  { tagName: "u shape", icon: <></> },
];
