import React, { memo, useContext, useState } from "react";
import { RxCross2 } from "react-icons/rx";
import { FaPhoneAlt, FaWhatsapp } from "react-icons/fa";
import santaAppUtils from "../../utils/santaAppUtils";
import AuthContext from "../../context/AuthContext";
import { IoHelp } from "react-icons/io5";

function ExpertHelp() {
  const {
    initialState: { santa },
  } = useContext(AuthContext);

  const [states, setStates] = useState({
    isCollapsed: true,
  });

  let styles = {
    width: states.isCollapsed ? "4.5rem" : "30px",
    height: states.isCollapsed ? "30px" : "30px",
  };

  const content = ` I'm ${santa.personalDetails?.name} an ebo santa. I'm facing an issues`;

  const onCallBtnClick = () => {
    try {
      window.open(`tel:${santaAppUtils().callingNumber}`, "_blank");
    } catch (error) {
      console.log(error);
    }
  };

  const onWhatsappBtnClick = () => {
    try {
      window.openUrl(
        `https://wa.me/${santaAppUtils().whatsappNumber}?text=${content}`,
        "_blank"
      );
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div
      style={styles}
      onClick={(e) => e.stopPropagation()}
      className={` transition-all duration-[.3s] rounded-full relative  blue-gradient items-center flex-center flex-shrink-0 z-[5] `}
    >
      <div
        style={{
          height: states.isCollapsed ? "0px" : "80px",
          opacity: states.isCollapsed ? "0" : "1",
        }}
        className=" flex-col gap-2 transition-all duration-[.5s] text-sm absolute top-[32px] right-0 flex-shrink-0 overflow-hidden"
      >
        <div
          onClick={onCallBtnClick}
          className=" gap-[6px] mirror-Animation  text-[white] font-medium hover:text-[white] cursor-pointer before:w-[20px] px-3 py-2 blue-gradient rounded-full flex-shrink-0 whitespace-nowrap flex items-center "
        >
          <FaPhoneAlt className="text-[.875rem] relative  " />
          <div className=" ">Call for help</div>
        </div>
        <div
          onClick={onWhatsappBtnClick}
          className=" gap-[6px] text-[white] font-medium hover:text-[white] mirror-Animation cursor-pointer before:w-[20px] px-3 py-2 green-gradient rounded-full flex-shrink-0 whitespace-nowrap flex items-center  "
        >
          <FaWhatsapp className="text-[1rem] relative bottom-[1px] " />
          <div className="  ">Chat with us</div>
        </div>
      </div>

      {states.isCollapsed ? (
        <div
          onClick={() => {
            setStates((p) => ({ ...p, isCollapsed: !p.isCollapsed }));
          }}
          className="flex relative cursor-pointer items-center whitespace-nowrap text-sm  "
        >
          Help <IoHelp className="text-[1rem]" />
        </div>
      ) : (
        <RxCross2
          onClick={() => {
            setStates((p) => ({ ...p, isCollapsed: !p.isCollapsed }));
          }}
          className=" text-[15px] cursor-pointer "
        />
      )}
    </div>
  );
}

export default memo(ExpertHelp);
