import { BiChevronLeft } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import ExpertHelp from "./headers/ExpertHelp";

export default function CommonHeader({ navFun, content }) {
  const navigate = useNavigate();

  return (
    <div
      onClick={() => {
        navFun ? navFun() : navigate(-1);
      }}
      className="sticky top-0 border-b py-3 px-2 flex items-center justify-between bg-white z-10 "
    >
      <div className="flex items-center text-[1rem]">
        <BiChevronLeft className="text-[1.5rem]" />
        {content ? content : <p>Back</p>}
      </div>
      <div className="flex  gap-4">
        <div className=" relative ">
          <ExpertHelp />
        </div>
      </div>
    </div>
  );
}
